export const Checkbox = {
  baseStyle: {
    control: {
      borderColor: 'textBlack.400',
      _hover: {
        borderColor: 'textBlack.800',
      },
    },
    label: {
      color: 'textBlack.800',
      _hover: {
        color: 'black',
      },
    },
  },
  defaultProps: {
    colorScheme: 'orange',
  },
};
