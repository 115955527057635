export const Menu = {
  parts: ['item', 'command', 'list', 'button', 'groupTitle'],
  variants: {
    main: {
      item: {
        color: 'textBlack.800',
        fontSize: 'sm',
        fontWeight: 500,
        lineHeight: 1.14,
        padding: '14px 24px',
        _hover: {
          backgroundColor: 'textBlack.100',
        },
        _focus: {
          backgroundColor: 'textBlack.400',
        },
      },
      list: {
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
        paddingTop: '12px',
        paddingBottom: '12px',
        borderRadius: '3px',
      },
    },
  },
};
