import React, { FC, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from './protected';

const HomePage = lazy(() => import('pages/HomePage'));
const AuthPage = lazy(() => import('pages/AuthPage'));
const OrderDetailPage = lazy(() => import('pages/OrderDetailPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage'));
const RefusalsPage = lazy(() => import('pages/RefusalsPage'));
const RefusalDetailPage = lazy(() => import('pages/RefusalDetailPage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage'));
const ReceptionsPage = lazy(() => import('pages/ReceptionsPage'));
const PriceListPage = lazy(() => import('pages/PriceListPage'));
const PriceListDetailPage = lazy(() => import('pages/PriceListDetailPage'));
const WareHousePage = lazy(() => import('pages/WareHousePage'));
const WareHouseDirectoryPage = lazy(() => import('pages/WareHouseDirectoryPage'));
const WareHouseStatusDetailPage = lazy(() => import('pages/WareHouseStatusDetailPage'));
const WareHouseFiltersPage = lazy(() => import('pages/WareHouseFiltersPage'));
const DictionaryPage = lazy(() => import('pages/DictionaryPage'));
const ActsCompletedWorkPage = lazy(() => import('pages/ActsCompletedWorkPage'));
const ActCompletedWorkDetailPage = lazy(() => import('pages/ActCompletedWorkDetailPage'));
const BillOfLadingsPage = lazy(() => import('pages/BillOfLadingsPage'));
const BillOfLadingsDetailPage = lazy(() => import('pages/BillOfLadingsDetailPage'));
const ReconciliationsPage = lazy(() => import('pages/ReconciliationsPage'));
const ReconciliationsDetailPage = lazy(() => import('pages/ReconciliationsDetailPage'));
const AgreementsPage = lazy(() => import('pages/AgreementsPage'));
const AgreementDetailPage = lazy(() => import('pages/AgreementDetailPage'));

const RootRouter: FC = () => (
  <Router>
    <Switch>
      <Route exact={true} path="/login" component={AuthPage} />
      <ProtectedRoute from="/" to="orders" exact={true} path="/" component={HomePage} />
      <ProtectedRoute path="/orders/refusals/:id" component={RefusalDetailPage} />
      <ProtectedRoute path="/orders/refusals" component={RefusalsPage} />
      <ProtectedRoute path="/orders/receptions" component={ReceptionsPage} />
      <ProtectedRoute path="/orders/:id" component={OrderDetailPage} />
      <ProtectedRoute path="/orders" component={HomePage} />
      <ProtectedRoute path="/notifications" component={NotificationsPage} />
      <ProtectedRoute path="/profile" component={ProfilePage} />
      <ProtectedRoute path="/price-list/:id" component={PriceListDetailPage} />
      <ProtectedRoute path="/price-list" component={PriceListPage} />
      <ProtectedRoute path="/warehouses/directory" component={WareHouseDirectoryPage} />
      <ProtectedRoute path="/warehouses/filters" component={WareHouseFiltersPage} />
      <ProtectedRoute path="/warehouses/:id" component={WareHouseStatusDetailPage} />
      <ProtectedRoute path="/warehouses" component={WareHousePage} />
      <ProtectedRoute path="/dictionary" component={DictionaryPage} />
      <ProtectedRoute
        path="/accounting/completions/:type/:id"
        component={ActCompletedWorkDetailPage}
      />
      <ProtectedRoute path="/accounting/completions" component={ActsCompletedWorkPage} />
      <ProtectedRoute
        path="/accounting/bill_of_ladings/:type/:id"
        component={BillOfLadingsDetailPage}
      />
      <ProtectedRoute path="/accounting/bill_of_ladings" component={BillOfLadingsPage} />
      <ProtectedRoute
        path="/accounting/reconciliations/:type/:id"
        component={ReconciliationsDetailPage}
      />
      <ProtectedRoute path="/accounting/reconciliations" component={ReconciliationsPage} />
      <ProtectedRoute path="/accounting/agreements/:type/:id" component={AgreementDetailPage} />
      <ProtectedRoute path="/accounting/agreements" component={AgreementsPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default RootRouter;
