export const Input = {
  parts: ['field', 'addon'],
  variants: {
    main: {
      field: {
        borderWidth: '2px',
        borderRadius: '3px',
        borderColor: 'textBlack.200',
        backgroundColor: 'textBlack.200',
        padding: '12px',
        height: 'auto',
        lineHeight: 1.23,
        _hover: {
          borderColor: 'textBlack.600',
        },
        _focus: {
          borderColor: 'textBlack.800',
        },
        _invalid: {
          borderColor: 'orange.600',
        },
      },
    },
  },
};
