import axios, { AxiosError } from 'axios';
import {
  accessTokenKey,
  companyIdKey,
  refreshTokenKey,
  hasActiveWarehousesKey,
} from 'store/auth/types';
import { PayloadActionCreator } from '@reatom/core';
import { ResponseTypes } from 'store/types';
import { API_URL } from 'core/server';

const token = localStorage.getItem(accessTokenKey);
const companyId = localStorage.getItem(companyIdKey);
const lang = localStorage.getItem('i18next_lang') || 'ru';

export const axiosRequest = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': lang,
    ...(token && { Authorization: `JWT ${token}` }),
  },
});

export const axiosMultiPartRequest = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept-Language': lang,
    ...(token && { Authorization: `JWT ${token}` }),
  },
});

export const axiosMediaTypeRequest = axios.create({
  headers: {
    'Media-Type': 'application/xlsx',
    'Accept-Language': lang,
    ...(token && { Authorization: `JWT ${token}` }),
  },
});

axiosRequest.interceptors.request.use((config) => {
  config.params = {
    // add your default ones
    ...(companyId && { company_id: companyId }),
    // spread the request's params
    ...config.params,
  };
  return config;
});

axiosMultiPartRequest.interceptors.request.use((config) => {
  config.params = {
    // add your default ones
    ...(companyId && { company_id: companyId }),
    // spread the request's params
    ...config.params,
  };
  return config;
});

axiosMediaTypeRequest.interceptors.request.use((config) => {
  config.params = {
    // add your default ones
    ...(companyId && { company_id: companyId }),
    // spread the request's params
    ...config.params,
  };
  return config;
});

axiosRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === `${API_URL}api/token/`) {
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(refreshTokenKey);
      localStorage.removeItem(hasActiveWarehousesKey);
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem(refreshTokenKey);
      return axios
        .post(`${API_URL}api/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(accessTokenKey, res.data.result.access);
            axios.defaults.headers.Authorization = `JWT ${res.data.result.access}`;
            window.location.reload();
          }
          return axios(originalRequest);
        })
        .catch(() => {
          localStorage.removeItem(accessTokenKey);
          localStorage.removeItem(refreshTokenKey);
          localStorage.removeItem(hasActiveWarehousesKey);
          window.location.reload();
        });
    }
    return Promise.reject(error);
  }
);

export const catchAxiosError = (payloadAction: PayloadActionCreator<string, string>) => (
  err: AxiosError<ResponseTypes>
) => payloadAction(err.response?.data?.message || 'Ошибка сервера');
