export const accessTokenKey = 'access_token';
export const refreshTokenKey = 'refresh_token';
export const employmentIdKey = 'employment_id';
export const companyIdKey = 'company_id';
export const isFilteredByRegionKey = 'is_filtered_by_region';
export const hasActiveWarehousesKey = 'has_active_warehouses';

export type TokenRequest = {
  readonly email: string;
  readonly password: string;
};

export type TokenState = {
  readonly refresh: string;
  readonly access: string;
};
