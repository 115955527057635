export const typography = {
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.375rem',
    '3xl': '1.5rem',
    '4xl': '1.175rem',
    '5xl': '1.75rem',
    '6xl': '2rem',
    '7xl': '2.25rem',
  },
};
