import { theme } from '@chakra-ui/react';

export const colors = {
  ...theme.colors,
  black: '#25282B',
  orange: {
    ...theme.colors.orange,
    50: '#FEECE6',
    70: '#FED9CD',
    100: '#FDC6B4',
    200: '#FDB39B',
    500: '#FB6A3C',
    600: '#DC5B3F',
    800: '#FB7A50',
    1200: '#E13A05',
  },
  textBlack: {
    50: '#F6F6FB',
    100: '#E7E7E9',
    200: '#E6E6EF',
    400: '#CACCCF',
    600: '#A0A4A8',
    700: 'rgba(52, 51, 61, 0.7)',
    800: '#52575C',
  },
  error: {
    100: '#FCDADA',
    400: '#FF6262',
    500: '#E93C3C',
    600: '#FB4E4E',
  },
  warning: {
    400: '#FFBC1F',
    500: '#E89806',
    600: '#F6A609',
  },
  success: {
    400: '#40DD7F',
    500: '#1AB759',
    600: '#2AC769',
  },
  blue: {
    ...theme.colors.blue,
    400: '#4075DD',
  },
};
