export const Button = {
  variants: {
    main: {
      backgroundColor: 'orange.500',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      borderRadius: '3px',
      color: 'white',
      padding: '14px',
      height: 'auto',
      _hover: {
        backgroundColor: 'orange.800',
        _disabled: {
          bg: 'orange.100',
        },
      },
      _active: {
        backgroundColor: 'orange.1200',
      },
      _focus: {
        backgroundColor: 'orange.1200',
        boxShadow: 'none',
      },
      _disabled: {
        backgroundColor: 'orange.100',
      },
    },
    secondary: {
      backgroundColor: 'orange.70',
      borderRadius: '3px',
      color: 'orange.500',
      padding: '14px',
      height: 'auto',
      fontWeight: 500,
      _hover: {
        backgroundColor: 'orange.100',
        _disabled: {
          bg: 'orange.70',
        },
      },
      _active: {
        backgroundColor: 'orange.200',
      },
      _focus: {
        backgroundColor: 'orange.200',
        boxShadow: 'none',
      },
      _disabled: {
        backgroundColor: 'orange.70',
        opacity: 0.5,
      },
    },
  },
};
