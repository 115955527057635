import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { typography } from './typography';
import { Button, Tabs, Input, Menu, Checkbox, Textarea } from './components';

const customTheme = {
  ...typography,
  colors,
  components: {
    Button,
    Tabs,
    Input,
    Menu,
    Checkbox,
    Textarea,
  },
};

export default extendTheme(customTheme);
