export const Tabs = {
  parts: ['tablist', 'tab', 'tabpanel'],
  variants: {
    main: {
      tablist: {
        backgroundColor: 'textBlack.200',
      },
      tab: {
        backgroundColor: 'textBlack.200',
        borderRadius: '3px',
        color: 'textBlack.800',
        margin: '4px',
        padding: '10px 24px',
        fontSize: 'sm',
        fontWeight: '500',
        borderLeft: '2px solid',
        borderColor: 'transparent',
        _selected: {
          color: 'orange.500',
          backgroundColor: 'white',
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
        },
        _active: {
          backgroundColor: 'textBlack.400',
        },
        _hover: {
          backgroundColor: 'textBlack.50',
        },
      },
      tabpanel: {
        padding: 0,
      },
    },
  },
};
