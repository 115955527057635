import { declareAtom } from '@reatom/core';
import { RemoteData } from 'remote-data-ts';
import * as axios from 'store/axios';
import * as actions from './actions';
import { TokenState, accessTokenKey, refreshTokenKey } from 'store/auth/types';

export const token = declareAtom<RemoteData<TokenState, string>>(RemoteData.notAsked(), (on) => [
  on(actions.tokenRequest, () => RemoteData.loading()),
  on(actions.tokenFail, (_, payload) => RemoteData.failure(payload)),
  on(actions.tokenSuccess, (_, payload) => {
    const { access, refresh } = payload;
    localStorage.setItem(accessTokenKey, access);
    localStorage.setItem(refreshTokenKey, refresh);
    axios.axiosRequest.defaults.headers.Authorization = `JWT ${access}`;
    axios.axiosMultiPartRequest.defaults.headers.Authorization = `JWT ${access}`;
    axios.axiosMediaTypeRequest.defaults.headers.Authorization = `JWT ${access}`;
    return RemoteData.success(payload);
  }),
]);

export const forgotPassword = declareAtom<RemoteData<unknown, string>>(
  RemoteData.notAsked(),
  (on) => [
    on(actions.forgotPasswordRequest, () => RemoteData.loading()),
    on(actions.forgotPasswordFail, (_, payload) => RemoteData.failure(payload)),
    on(actions.forgotPasswordSuccess, (_, payload) => RemoteData.success(payload)),
  ]
);
