import React, { FC, Suspense } from 'react';
import { context } from '@reatom/react';
import { ChakraProvider, Spinner, Box } from '@chakra-ui/react';
import RootRouter from 'routes';
import { store } from 'store';
import customTheme from 'core/theme';

const FallBackSpinner: FC = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="orange.500" size="xl" />
  </Box>
);

export const AppTemplate: FC = () => (
  <ChakraProvider theme={customTheme}>
    <Suspense fallback={<FallBackSpinner />}>
      <context.Provider value={store}>
        <RootRouter />
      </context.Provider>
    </Suspense>
  </ChakraProvider>
);
