import * as axios from 'store/axios';
import { AxiosResponse } from 'axios';
import { API_URL } from 'core/server';
import { TokenRequest, TokenState, ForgotPasswordRequest } from 'store/auth/types';
import { ResponseTypes } from 'store/types';

export const token = ({
  email,
  password,
}: TokenRequest): Promise<AxiosResponse<ResponseTypes<TokenState>>> =>
  axios.axiosRequest.post(API_URL + 'api/token/', {
    email: email.toLocaleLowerCase(),
    password,
  });

export const forgotPassword = ({
  email,
}: ForgotPasswordRequest): Promise<AxiosResponse<ResponseTypes<unknown>>> =>
  axios.axiosRequest.post(API_URL + 'api/user/forgot_password/', {
    email: email.toLocaleLowerCase(),
  });
