import React, { FC, useMemo } from 'react';
import { accessTokenKey } from 'store/auth/types';
import { Route, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';

export const ProtectedRoute: FC<{
  component: FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  from?: string;
  to?: string;
}> = (props) => {
  const location = useLocation();
  const isLogged = useMemo(() => !!localStorage.getItem(accessTokenKey), []);

  const { from, to = '/' } = props;
  return isLogged ? (
    from ? (
      <Redirect exact={true} from={from} to={to} />
    ) : (
      <Route {...props} />
    )
  ) : (
    <Redirect to={{ pathname: '/login', state: { redirectUrl: location.pathname } }} />
  );
};
