import { declareAction } from '@reatom/core';
import * as api from 'store/auth/api';
import { TokenRequest, TokenState } from 'store/auth/types';
import { catchAxiosError } from 'store/axios';

export const tokenFail = declareAction<string>();
export const tokenSuccess = declareAction<TokenState>();
export const tokenRequest = declareAction<TokenRequest>(
  async (payload, store) =>
    await api
      .token(payload)
      .then((res) => store.dispatch(tokenSuccess(res.data.result)))
      .catch((err) => store.dispatch(catchAxiosError(tokenFail)(err)))
);

export const forgotPasswordFail = declareAction<string>();
export const forgotPasswordSuccess = declareAction<unknown>();
export const forgotPasswordRequest = declareAction<TokenRequest>(
  async (payload, store) =>
    await api
      .forgotPassword(payload)
      .then((res) => store.dispatch(forgotPasswordSuccess(res.data.result)))
      .catch((err) => store.dispatch(catchAxiosError(forgotPasswordFail)(err)))
);
